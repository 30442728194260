import React from 'react';
import Netflix from '../../images/netflix.svg';
import Authentication from '../../images/authentication.svg';
import Connect from '../../images/connect.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

import MoreProjects from '../moreProjects';
function Projects() {
  AOS.init();
  return (
    <>
      {/* all */}
      <div className='flex w-full h-full md:py-5 py-2 bg-slate-950  '>
        {/* project, projects, more project */}
        <div className='flex flex-col md:flex-row w-full h-full md:px-4 px-2 items-center'>
          {/* heading 1 col */}
          <div className='flex  h-full md:w-[20%] w-full justify-center md:pb-0 pb-4'>
            <p
              data-aos={'fade-right'}
              className='md:text-4xl text-3xl font-extrabold bg-gradient-to-r from-blue-500  via-white to-red-400 bg-clip-text text-transparent shadow-[5px_15px_10px_-10px_white] bg-transparent'
            >
              Projects
            </p>
          </div>
          {/* project 2 col */}
          <div className='flex flex-row h-full md:w-[60%] w-full justify-center items-center md:px-3 px-0'>
            {/* net + auth + visiting */}
            <div className='w-full h-full flex flex-row justify-center items-center md:gap-x-3 gap-x-2'>
              {/* Netflix */}
              <div
                className='group relative h-48 md:w-[70%] w-full flex flex-col  rounded-md shadow-[0_1px_20px_-10px_white] overflow-hidden cursor-pointer'
                data-aos={'zoom-in-right'}
              >
                <div
                  className='h-full w-full bg-cover bg-no-repeat bg-center rounded-md '
                  style={{ backgroundImage: `url(${Netflix})` }}
                ></div>
                <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black rounded-md text-white'>
                  <div className='absolute inset-0 flex flex-col justify-center items-center text-center p-3 rounded-md backdrop-blur-[1.5px] translate-y-[100%] group-hover:translate-y-0 ease-out duration-200'>
                    <h1 className='md:text-xl text-sm font-bold'>
                      Netflix Clone
                    </h1>
                    <p className='md:text-md text-xs'>
                      React Js, Firebase, Tailwind Css, JavaScript
                    </p>
                  </div>
                </div>
              </div>
              {/* authentication */}
              <div
                className='group relative bg-neutral-400  h-48 md:w-[70%] w-full flex flex-col  rounded-md shadow-[0_1px_20px_-10px_white] overflow-hidden cursor-pointer'
                data-aos={'zoom-in-right'}
              >
                <div
                  className='w-full h-full bg-cover bg-no-repeat bg-center rounded-md'
                  style={{ backgroundImage: `url(${Authentication})` }}
                ></div>
                <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black rounded-md text-white'>
                  <div className='absolute inset-0 flex flex-col justify-center items-center text-center p-3 rounded-md backdrop-blur-[1.5px] translate-y-[100%] group-hover:translate-y-0 ease-out duration-200'>
                    <h1 className='md:text-xl text-sm font-bold'>
                      Authentication <p>&</p> Authorization
                    </h1>
                    <p className='md:text-md text-xs'>
                      Node Js, JWT, Midleware, MongoDB, JavaScript
                    </p>
                  </div>
                </div>
              </div>
              {/* visiting */}

              <div
                data-aos={'zoom-in-right'}
                className='group relative h-48 md:w-[70%] w-full bg-gradient-to-b from-slate-400 via-orange-300 to-red-300 rounded-md shadow-[0_1px_20px_-10px_white] overflow-hidden cursor-pointer'
              >
                <a
                  href='https://github.com/vinit-r/Digital-Visiting-Card'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='flex justify-center w-full h-full rounded-md p-2'>
                    <img src={`${Connect}`} alt='' />
                  </div>
                  <div className='absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black'>
                    <div className='absolute bg-blackCover backdrop-blur-[1.5px] inset-0 flex flex-col justify-center items-center text-center p-3 translate-y-[100%] group-hover:translate-y-[0%] ease-out duration-200'>
                      <h1 className='text-white md:text-xl text-sm font-bold'>
                        Digital Visiting <p>Card</p>
                      </h1>
                      <p className='text-white md:text-md text-xs font-medium'>
                        JavaScript, CSS3, HTML5
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* 3 col */}
          <div className='md:w-[20%] w-full h-full '>
            <MoreProjects />
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
