import aboutVinit from '../../images/vinit_edit.jpg';
import Header from '../header';
import Education from '../education';
import Experience from '../experience';
import Achivement from '../achivement';
import Footer from '../footer';
import resume from '../../pdfDocs/Nresume.pdf';
// md:bg-black bg-[url('/src/images/vinit_edit.jpg')] bg-cover bg-no-repeat bg-center

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function About() {
  return (
    <>
      <Header />
      <div className='w-full h-full bg-black flex flex-col'>
        <div className="flex flex-row items-center justify-center md:bg-none bg-[url('/src/images/vinit_edit.jpg')] bg-cover bg-no-repeat bg-center ">
          <div className='md:bg-transparent bg-blackCover relative md:w-[60%] w-full h-full flex'>
            <div className='text-white text-center p-8'>
              <p className='md:text-6xl text-4xl font-bold pb-6'>
                Vinit Rahangdale
              </p>
              <p className='text-sm text-gray-400'>
                Web development enthusiast with a year of hands-on experience in
                MERN, JavaScript, Java, CSS3, HTML5, and Tailwind CSS. Quick
                learner, passionate about clean code, and dedicated to crafting
                engaging, efficient, and scalable web solutions.
              </p>
              <div className='pt-6'>
                <a href={resume} target='_blank' rel='noreferrer'>
                  <button className='text-lg p-2 bg-[#fb8500]/50 hover:bg-green-400  rounded-sm'>
                    View Resume
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className=' md:w-[40%] w-0 md:h-full h-0'>
            <img className='h-[100%]' src={`${aboutVinit}`} alt='' />
          </div>
        </div>
        {/* <div className='w-full h-[400px]'></div> */}
        <Experience />
        <Education />
        <Achivement />
        <Footer />
      </div>
    </>
  );
}
export default About;
