import TopBanner from '../topBanner';
import Skills from '../skills';
import Projects from '../projects';
import Footer from '../footer';
import Header from '../header';
function Home() {
  return (
    <>
      <Header />
      <TopBanner />
      <Skills />
      <Projects />
      <Footer />
    </>
  );
}
export default Home;
