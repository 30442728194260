import React from 'react';
import { useEffect } from 'react';
import './index.css';
import {
  faInstagram,
  faLinkedinIn,
  faXTwitter,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const socialMidea = [
  {
    name: 'Instagram',
    icon: faInstagram,
    className: 'insta',
    link: 'https://www.instagram.com/vinit___pawar/',
  },
  {
    name: 'Linkdein',
    icon: faLinkedinIn,
    className: 'linkedin',
    link: 'https://www.linkedin.com/in/vinit-rahangdale-a1864a245',
  },
  {
    name: 'Twitter',
    icon: faXTwitter,
    className: 'twitter',
    link: 'https://twitter.com/VinitPawar2112',
  },
  {
    name: 'Github',
    icon: faGithub,
    className: 'git',
    link: 'https://github.com/vinit-r',
  },
];

function Footer() {
  // useEffect(() => {}, []);
  let doResizeResult = () => {
    console.log(window.innerWidth);
  };

  let debounce = (callback, delay) => {
    let myTimeout;
    return () => {
      clearTimeout(myTimeout);
      myTimeout = setTimeout(() => {
        callback();
      }, delay);
    };
  };

  let doDebounce = debounce(() => doResizeResult(), 1000);

  window.addEventListener('resize', () => doDebounce());
  return (
    <>
      <div className='w-full h-full bg-black px-1 py-2'>
        <div className='flex flex-col w-full h-full justify-center items-center '>
          <div className='grid grid-cols-4 text-white w-[35%]  justify-between gap-x-2'>
            {socialMidea.map((items) => {
              return (
                <>
                  <a
                    className={`p-1 hover:text-zinc-400 text-sm ${items?.className}`}
                    href={`${items?.link}`}
                  >
                    {window.innerWidth <= 768 ? (
                      <FontAwesomeIcon icon={items?.icon} />
                    ) : (
                      items?.name
                    )}
                    {/* {items?.name} */}
                  </a>
                </>
              );
            })}
          </div>
          <div className='text-zinc-400 mt-2 md:text-sm text-xs'>
            <p>Copyright &copy; 2023 Vinit Rahangdale</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
