import React from 'react';

function Education() {
  return (
    <>
      <div className='flex md:flex-row flex-col-reverse md:p-8 p-4 w-full h-full'>
        <div className='md:w-[70%] w-full h-full flex flex-col md:grid md:grid-cols-2 gap-3 font-bold text-white'>
          <div className='bg-gradient-to-br from-gray-500/90 to-zinc-900 w-full h-full rounded-md flex flex-col p-4 '>
            <p className='text-lg'>Dr. A.P.J. Abdul Kalam University</p>
            <p className='text-xs mb-3'>Master's In Computer Application</p>
            <p className='text-xs mb-1'>CGPA - 8.5</p>
            <p className='text-xs'>2021-2023 | Indore Madhya Pradesh </p>
          </div>
          <div className='bg-gradient-to-br from-gray-500/90 to-zinc-900 w-full h-full rounded-md flex flex-col p-4'>
            <p className='text-lg'>Motilal Vigyan Mahavidhyalaya</p>
            <p className='text-xs mb-3'>Bachelor of Science (BSC)</p>
            <p className='text-xs mb-1'>Percent - 62.8</p>
            <p className='text-xs'>2017-2020 | Bhopal Madhya Pradesh </p>
          </div>
          <div className='bg-gradient-to-br from-[#b08968] to-zinc-900 w-full h-full rounded-md flex flex-col p-4'>
            <p className='text-lg'>Mont Fort Higher Secondary School</p>
            <p className='text-xs mb-3'>Higher Secondary School (12th PCM)</p>
            <p className='text-xs mb-1'>Percent - 75.8</p>
            <p className='text-xs'>2016-2017 | Balaghat Madhya Pradesh </p>
          </div>
          <div className='bg-gradient-to-br from-[#b08968] to-zinc-900 w-full h-full rounded-md flex flex-col p-4'>
            <p className='text-lg'>Mont Fort Higher Secondary School</p>
            <p className='text-xs mb-3'>High School (10th ALL)</p>
            <p className='text-xs mb-1'>Percent - 87.33</p>
            <p className='text-xs'>2014-2015 | Balaghat Madhya Pradesh </p>
          </div>
        </div>
        {/* <div className='w-[30%] h-full flex justify-end'>
          <p className='text-white font-bold text-4xl'>Education</p>
        </div> */}
        <div className='text-white font-extrabold overflow-hidden md:text-7xl text-6xl relative md:w-[30%] w-full h-full flex justify-center mb-4 md:mb-0'>
          <p className='opacity-5'>Education</p>
          <p className=' absolute top-0 md:left-10 left-20 text-4xl font-semibold text-[#fb8500]/50'>
            Education
          </p>
        </div>
      </div>
    </>
  );
}

export default Education;
