import React from 'react';
import { Link } from 'react-router-dom';
import pic from '../../images/vinit.JPG';

function Header() {
  return (
    <>
      <div className='flex sticky top-0 z-20 '>
        <div className='bg-black w-full h-[70px] flex flex-row items-center justify-between md:text-xl text-base'>
          <div className='text-white flex flex-row items-center justify-between h-full md:w-[25%] w-full  md:px-5 px-2 py-2'>
            <img
              className='aspect-square object-cover w-14 h-14 rounded-full '
              src={`${pic}`}
              alt='My pic'
            />
            <p className='cursor-no-drop pl-2'>My Portfolio</p>
          </div>
          <div className='w-full h-full flex justify-end items-center gap-x-7 px-5'>
            <Link to='/'>
              <p className='text-white cursor-pointer hover:text-gray-400 '>
                Home
              </p>
            </Link>
            <Link to='/about'>
              <p className='text-white cursor-pointer hover:text-gray-400'>
                About
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
