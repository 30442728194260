import React from 'react';

import PromptLogo from '../../images/brain_circuit.svg';
import tcsION from '../../pdfDocs/Vinit_Rahangdale_TCS_ION.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenRuler,
  faArrowCircleDown,
  faEye,
  faStarOfDavid,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

function Achivement() {
  return (
    <>
      <div className='flex flex-col md:grid md:grid-cols-3  w-full h-full place-items-center md:px-8 px-4  mb-10'>
        <div className='w-full h-full rounded-md bg-zinc-900/70 shadow-2xl flex flex-row place-items-center p-2'>
          <div className='text-zinc-700 flex w-[20%] overflow-hidden'>
            <FontAwesomeIcon
              className='rounded-full border-[2px] border-zinc-800 w-10 h-10  p-2'
              icon={faPenRuler}
            />
          </div>
          <div className='text-zinc-200/80 text-xs flex flex-col w-[60%] px-2 '>
            <div className=' md:text-base text-sm flex items-center justify-center mb-2'>
              <p>TCS iON Career Edge- Young Professional</p>
            </div>
            <p>Type - Certificate Course</p>
            <p>Date - 2022</p>
          </div>
          <div className='text-zinc-700 hover:text-green-400 flex w-[20%] justify-center '>
            <a href={tcsION} target='_blank' rel='noreferrer'>
              <FontAwesomeIcon className='w-10 h-10' icon={faEye} />
            </a>
          </div>
        </div>

        <div className='text-white font-extrabold md:text-[65px] overflow-hidden text-6xl relative  w-full h-full flex justify-center items-center py-5 md:py-0'>
          <p className='opacity-5'>Achivement</p>
          <p className=' absolute md:top-[18%] top-[20%] md:left-[10%] left-[25%] text-4xl font-semibold text-[#fb8500]/50'>
            Achivement
          </p>
        </div>

        <div className='w-full h-full rounded-md bg-zinc-900/70 shadow-2xl flex flex-row place-items-center p-2 '>
          <div className='text-zinc-700 flex w-[20%] overflow-hidden'>
            <FontAwesomeIcon
              className='rounded-full border-[2px] border-zinc-800 w-10 h-10  p-2'
              icon={faStarOfDavid}
            />
          </div>
          <div className='text-zinc-200/80 text-xs flex flex-col w-[60%] px-2'>
            <div className=' md:text-base text-sm flex items-center justify-center mb-2'>
              <p>4 Start Coder On Hackerrank</p>
            </div>
            <p>Plateform - Hackerrank</p>
            <p>Date - Present</p>
          </div>
          <div className='text-zinc-700 hover:text-green-400 flex w-[20%] justify-center'>
            <a
              href='https://www.hackerrank.com/profile/vinitr2021'
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon className='w-8 h-8' icon={faChevronRight} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Achivement;
