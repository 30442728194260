import Typewriter from 'typewriter-effect';

function TopBanner() {
  return (
    <>
      <div className='relative flex md:flex-row flex-col shadow-2xl overflow-hidden'>
        <div className="absolute md:bg-[url('/public/programming.jpg')] bg-cover bg-no-repeat bg-center md:w-[60%] w-full md:h-[140%] h-1/2 md:rotate-[6deg] rotate-0 md:left-[50%] md:top-[-10%] top-[0%] left-[0%] "></div>
        <div className=' w-full md:w-1/2 h-[300px] md:h-[400px] flex items-center pl-8 '>
          <div>
            <div className='md:text-4xl text-3xl font-extrabold bg-gradient-to-r from-blue-500  via-black to-red-400 bg-clip-text text-transparent inline-block'>
              <h1>Hello,</h1>
              {/* <p>
                I'm
                <Typewriter
                  className='inline'
                  options={{
                    strings: ['Vinit Rahangdale', 'Software Developer'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </p> */}
              <Typewriter
                onInit={(typewriter) => {
                  const words = ['Vinit Rahangdale', 'Software Developer'];
                  typewriter.typeString("I'm ");

                  for (let index = 0; index < words.length; index++) {
                    typewriter
                      .typeString(words[index])
                      .pauseFor(500)
                      .deleteChars(words[index].length);
                  }
                  typewriter.start();
                }}
                options={{
                  loop: true,
                }}
              />
            </div>
            <p className='pr-10'>
              Enthusiastic software developer with expertise in Java, CSS,HTML,
              JavaScript, React.js, Node.js, and MySQL. Strongproblem-solving
              skills and ability to adapt to newtechnologies quickly. Proven
              track record of workingeffectively in team environments.
            </p>
          </div>
        </div>
        <div className=' w-0 md:w-1/2 h-0 md:h-[400px]'> </div>
      </div>
    </>
  );
}
export default TopBanner;
