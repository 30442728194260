import React from 'react';
import Zoho from '../../images/zoho_icon.svg';
import ChatBot from '../../images/chatbase_icon.svg';
import Smart from '../../images/contact_book.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

const projectDetails = [
  {
    projectName: 'Zoho CRUD',
    projectIcon: Zoho,
    projectLanguage: 'Node Js, JavaScript, MongoDB, Axios',
    fontIcon: faChevronRight,
    link: 'https://github.com/vinit-r/EMIXCHANGE-Problem5-apjAbdulKalamUniversityIndore-MCA-vinitRahangdale/tree/master',
  },
  {
    projectName: 'ChatBot',
    projectIcon: ChatBot,
    projectLanguage: 'JavaScript, Node Js, Meta API, EndPoint,',
    fontIcon: faChevronRight,
    link: 'https://github.com/vinit-r/EMIXCHANGE-Problem4-apjAbdulKalamUniversityIndore-MCA-vinitRahangdale',
  },
  {
    projectName: 'Smart Contact',
    projectIcon: Smart,
    projectLanguage: 'Java, SpringBoot, MySQL, MVC, Tomcat',
    fontIcon: faChevronRight,
    link: 'https://github.com/vinit-r/Smart-contact-management-system/tree/main',
  },
];

function MoreProjects() {
  return (
    <>
      <div
        data-aos={'fade-right'}
        className='flex flex-col border-[1px] border-zinc-700/90 w-full h-full mr-4 mt-6 md:mt-0 rounded-md font-Inter'
      >
        <div className=' w-full flex justify-center pt-2 text-center '>
          <p className='md:text-2xl text-lg font-extrabold bg-gradient-to-r from-blue-500  via-white to-red-400 bg-clip-text text-transparent'>
            More Projects
          </p>
        </div>
        <div className=' w-full md:flex flex-col grid grid-cols-3 justify-center items-center py-2 px-1 '>
          {projectDetails.map((items) => {
            return (
              <>
                <a href={`${items?.link}`} target='_blank' rel='noreferrer'>
                  <div className='md:flex md:flex-row grid grid-rows-2  justify-between w-full md:p-4 p-1 md:rounded-md rounded-none cursor-pointer border-r-[1px] md:border-r-0 hover:bg-zinc-800/50'>
                    <div className='md:w-[30%] w-full flex justify-center '>
                      <div className='flex justify-center items-center '>
                        <img
                          className='md:w-[60%] w-[70%]'
                          src={`${items?.projectIcon}`}
                          alt='Chat Bot Logo'
                        />
                      </div>
                    </div>

                    <div className='flex flex-row w-full md:w-[70%] justify-center items-center'>
                      <div className='text-xs font-extralight w-[80%] flex flex-col justify-center text-center'>
                        <p className='text-zinc-500/90 font-bold text-base'>
                          {items?.projectName}
                        </p>
                        <p className='text-zinc-500/50'>
                          {items?.projectLanguage}
                        </p>
                      </div>
                      <div className=' text-zinc-500/50 font-bold text-lg w-[20%] hidden md:flex justify-center items-center'>
                        <FontAwesomeIcon icon={items?.fontIcon} />
                      </div>
                    </div>
                  </div>
                </a>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MoreProjects;
