import React from 'react';

import PromptLogo from '../../images/brain_circuit.svg';
import javaCert from '../../pdfDocs/java_certificate.pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCity } from '@fortawesome/free-solid-svg-icons';

function Experience() {
  return (
    <>
      <div className='w-full h-full flex flex-col justify-center items-center'>
        <div className='text-white font-extrabold md:text-7xl text-6xl relative'>
          <p className='opacity-5'>Experience</p>
          <p className=' absolute top-0 md:left-24 left-20 text-4xl text-[#fb8500]/50 font-semibold'>
            Experience
          </p>
        </div>
        <div className='flex md:grid md:grid-cols-3 flex-col w-full md:gap-x-5 gap-y-3 md:px-8 p-4 md:py-4 '>
          <div className='w-full h-full rounded-md bg-zinc-900/70 shadow-2xl grid grid-rows place-items-center px-6 py-2 '>
            <div className='text-zinc-700 flex relative'>
              <FontAwesomeIcon
                className='rounded-full border-[2px] border-zinc-800 w-16 h-16 p-2'
                icon={faCode}
              />
              <p className='absolute text-white md:left-[155%] left-[115%] top-[-5%] text-xs border-[1px] border-gray-500 hover:border-white p-[2px] rounded-sm cursor-pointer bg-gradient-to-l from-[#fb8500]/40 to-[#fb8500]'>
                Certificate
              </p>
            </div>
            <div className='text-zinc-500 text-sm font-semibold flex flex-col pb-2'>
              <div className='text-zinc-200/80 md:text-2xl text-xl font-bold flex items-center text-center justify-center mb-2'>
                <p>Product Developer</p>
              </div>
              <p className='text-zinc-200/80'>Company - EMIXCHANGE PVT. LTD.</p>
              <p className='text-zinc-200/80 mb-2'>
                Duration - 2nd JAN 2023 To 19th AUG 2023
              </p>
              <p>
                Full-stack developer adept in MERN stack. Proven record in
                client collaboration, requirement analysis, and product roadmap
                development, resulting in a 20% boost in customer satisfaction.
              </p>
            </div>
          </div>
          <div className='w-full h-full rounded-md bg-zinc-900/70 shadow-2xl grid grid-rows place-items-center px-6 py-2 '>
            <div className='text-zinc-700 flex relative'>
              <FontAwesomeIcon
                className='rounded-full border-[2px] border-zinc-800 w-14 h-14 p-3'
                icon={faCity}
              />
              <a href={javaCert} target='_blank' rel='noreferrer'>
                <p className='absolute text-white md:left-[155%] left-[115%] md:top-[-10%] top-[-5%] text-xs border-[1px] border-gray-500 hover:border-white p-[2px] rounded-sm cursor-pointer bg-gradient-to-l from-[#fb8500]/40 to-[#fb8500]'>
                  Certificate
                </p>
              </a>
            </div>
            <div className='text-zinc-500 text-sm font-semibold flex flex-col pb-2'>
              <div className='text-zinc-200/80 md:text-2xl text-xl font-bold flex items-center text-center justify-center mb-2 '>
                <p>Trainee Software Developer</p>
              </div>
              <p className='text-zinc-200/80'>Company - A2 Infotech</p>
              <p className='text-zinc-200/80 mb-2'>
                Duration - SEP 2022 To DEC 2022
              </p>
              <p>
                Master Core Java (JSE) with in-depth instruction on
                object-oriented programming, Java APIs, and hands-on coding
                skillsfor real-world development.
              </p>
            </div>
          </div>
          <div className='w-full h-full rounded-md bg-zinc-900/70 shadow-2xl grid grid-rows place-items-center px-6 py-2 '>
            <div className='text-zinc-700 flex '>
              <img
                className='rounded-full border-[2px] border-zinc-800 w-20 h-20 p-2'
                src={`${PromptLogo}`}
                alt=''
              />
            </div>
            <div className='text-zinc-500 text-sm font-semibold flex flex-col pb-2'>
              <div className='text-zinc-200/80 md:text-2xl text-xl font-bold flex items-center text-center justify-center mb-2'>
                <p>Prompt Engineering</p>
              </div>
              <p className='text-zinc-200/80'>
                Source - Andrew Ng (DeepLearning.AI)
              </p>
              <p className='text-zinc-200/80 mb-2'>Time - 2023</p>
              <p>
                Acquired proficiency in prompt engineering through ChatGPT
                course, learning effective prompt creation, OpenAI API
                utilization, and practical applications in text tasks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Experience;
