// Frontend Import
import HTML5 from '../../images/html_5.svg';
import CSS3 from '../../images/css.svg';
import JS from '../../images/js.svg';
import REACT from '../../images/reactjs.svg';
import Tailwind from '../../images/tailwind.svg';

// Backend Import
import Node from '../../images/nodejs.svg';
// import Express from '../../images/express.png';
import Express from '../../images/expressjs_n.svg';
import Java from '../../images/java.svg';

// Database
import MongoDB from '../../images/mongodb.svg';

// Devops
import Git from '../../images/github_icon.svg';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function Skills() {
  return (
    <>
      <div className='flex flex-col bg-gradient-to-r from-black via-[#14213d]  to-black w-full md:h-[500px] md:pb-0 pb-10'>
        <div className='pl-8 pt-4'>
          <div className='h-14 w-28 rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[0.5px] animate'>
            <div className='flex h-full w-full rounded-md items-center justify-center bg-black back'>
              <h1 className='md:text-4xl text-3xl font-extrabold bg-gradient-to-r from-blue-500  via-white to-red-400 bg-clip-text text-transparent'>
                Skills
              </h1>
            </div>
          </div>
          {/* <h className='text-4xl font-extrabold bg-gradient-to-r from-blue-500  via-white to-red-400 bg-clip-text text-transparent animate border-b-[3px] w-24 border-red-300'>
            Skills
          </h> */}
        </div>
        <div className='md:flex md:flex-row grid grid-cols-1  w-full h-full justify-center md:gap-x-5 gap-y-2 md:pt-10 pt-5 px-2'>
          {/* Frontend */}
          <div
            data-aos={'fade-down'}
            className='flex flex-col bg-gradient-to-r from-[#023047] via-[#14213d]  to-black md:w-1/3 md:h-[90%] w-full h-full rounded-lg drop-shadow-xl '
          >
            <div className='flex items-center justify-center md:text-2xl text-xl text-white'>
              Frontend
            </div>
            <div className='grid grid-cols-3 place-items-center md:p-4 p-2'>
              <p className='flex flex-col items-center font-bold text-white text-sm justify-centers md:w-[85%] w-20'>
                <img src={`${HTML5}`} alt='Git image' /> HTML5
              </p>
              {/* <img src={`${HTML5}`} alt='HTML5 Image' /> */}

              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20'>
                <img src={`${CSS3}`} alt='Git image' /> CSS3
              </p>

              <p className='flex flex-col items-center font-bold text-white text-sm text-center justify-center md:w-[85%] w-20'>
                <img src={`${Tailwind}`} alt='Git image' />
                Tailwind CSS
              </p>
              {/* <img src={`${CSS3}`} alt='CSS3 Image' /> */}

              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20'>
                <img src={`${JS}`} alt='Git image' /> JavaScript
              </p>
              {/* <img src={`${JS}`} alt='JS Image' /> */}

              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20'>
                <img src={`${REACT}`} alt='Git image' /> React JS
              </p>
              {/* <img src={`${REACT}`} alt='Tailwind CSS Image' /> */}
            </div>
          </div>
          {/* Backend */}

          <div
            data-aos={'fade-up'}
            className='flex flex-col bg-gradient-to-r from-black via-[#14213d]  to-[#023047] md:w-1/4 w-full md:h-[90%] h-full rounded-lg'
          >
            <div className='flex items-center justify-center md:text-2xl text-xl text-white'>
              BackEnd
            </div>
            <div className='grid md:grid-cols-2 place-items-center grid-cols-3 md:p-4 p-2 '>
              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20'>
                <img src={`${Node}`} alt='Git image' /> Node JS
              </p>
              {/* <img src={`${Node}`} alt='NodeJs image' /> */}
              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20 '>
                <img
                  className='border-[2px] rounded-full p-6 text-white'
                  src={`${Express}`}
                  alt='Git image'
                />{' '}
                Express JS
              </p>
              {/* <img src={`${Express}`} alt='ExpressJs Image' /> */}

              <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[85%] w-20'>
                <img src={`${Java}`} alt='Git image' /> Java
              </p>
              {/* <img src={`${Java}`} alt='Java Image' /> */}
            </div>
          </div>

          <div
            data-aos={'fade-down'}
            className='flex md:flex-col flex-row md:gap-y-3 gap-x-2 md:w-1/4 w-full'
          >
            {/* DataBase */}
            <div className='flex flex-col bg-gradient-to-r from-[#023047] via-[#14213d]  to-black w-full md:h-[43%] h-full rounded-lg'>
              <div className='flex items-center justify-center md:text-2xl text-xl text-white'>
                Database
              </div>
              <div className='grid grid-cols-1 place-items-center md:p-4 p-2'>
                <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[30%] w-20 '>
                  <img src={`${MongoDB}`} alt='Git image' /> MongoDB
                </p>
                {/* <img src='/public/logo512.png' alt='ExpressJs Image' /> */}
              </div>
            </div>

            {/* Devops */}
            <div className='flex flex-col bg-gradient-to-r from-[#023047] via-[#14213d]  to-black w-full md:h-[43%] h-full  rounded-lg'>
              <div className='flex items-center justify-center md:text-2xl text-xl text-white'>
                Devops
              </div>
              <div className='grid grid-cols-1 place-items-center md:p-4 p-2'>
                <p className='flex flex-col items-center font-bold text-white text-sm justify-center md:w-[30%] w-20 '>
                  <img src={`${Git}`} alt='Git image' width={'100%'} /> Git
                </p>
                {/* <img src='/public/logo512.png' alt='ExpressJs Image' /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Skills;
